// CSS RESET
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*:focus {outline:none !important}
button:focus {outline:0!important;}

// COLOR VARIABLES
$primary: #0DA338;
$primary-hover: #0F8331;
$white: #ffffff;
$black: #333333;
$gray: #aaa;
$gray-1: #666;
$light-gray: #f5f5f5;
$light-gray-1: #e5e5e5;

// IMPORT BOOTSTRAP
@import "../node_modules/bootstrap/scss/bootstrap";

// UTILITIES VARIABLES
$border-radius: 4px;
$transition: all .2s ease-in-out;

// MIXIN
@mixin full-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
@mixin full-absolute($full-absolute) {
    position: absolute;
    left: $full-absolute;
    right: $full-absolute;
    top: $full-absolute;
    bottom: $full-absolute;
}

// BODY
body {
    font-family: 'Open Sans', sans-serif;
    position: relative;
    display: block;
    background: $white;
    font-size: .9rem;
    line-height: 1.4;
}
h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
}
.text-center {
    text-align: center!important;
}
.font-weight-bolder {
    font-weight: 600;
}
// .navbar {
//     height: 60px;
// }
.navbar-brand {
    img {
        width: 90px;
    }
}
.main-logo-footer {
    width: 120px;
}
.nav-link {
    color: $black!important;
    font-weight: 600;
    &:hover{
        color: $primary!important;
    }
}
.bg-primary-2 {
    background: #05581C;
}
// BUTTON
.button,
.btn {
    display: inline-flex;
    padding: 12px 16px;
    border-radius: $border-radius;
    transition: $transition;
    text-decoration: none;
    font-weight: 600;
    font-size: .9rem;
    &:hover,
    &:focus {
        text-decoration: none;
        background: $light-gray;
        color: $black;
    }
    &.button-primary {
        background: $primary;
        color: $white;
        border-color: $primary;
        &:hover {
            background: $primary-hover;
            border-color: $primary-hover;
            color: $white;
        }
    }
    &.button-outline-primary {
        background: transparent;
        color: $primary;
        border: 1px solid $primary;
        &:hover {
            background: $primary;
            border-color: $primary;
            color: $white;
        }
    }
    &.button-block {
        display: flex;
        justify-content: center;
    }
    &.button-cta {
        padding: 18px 16px;
        font-size: 1rem;
    }
    &.btn-sm {
        padding: 6px 12px;
        font-size: .8rem;
    }
}

// MAIN-HEADER
.main-nav {
    display: block;
    position: relative;
    height: 70px;
    background-color: $white;
    padding: 0px 24px;
    .main-nav--row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }
    .main-nav--col {
        &.right {
            text-align: right;
        }
    }
}
.main-nav--list {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li {
        list-style: none;
        a {
            text-decoration: none;
            padding: 12px 16px;
            color: $black;
            background: $white;
            border-radius: $border-radius;
            transition: $transition;
            &:hover,
            &:focus{
                color: $black;
                text-decoration: none;
                background: $light-gray;
            }
        }
        &.main-nav--cta {
            a {
                background: $primary;
                color: $white;
                font-weight: 600;
                &:hover {
                    background: $primary-hover;
                }
            }
        }
    }
}
.nav-modal {
    cursor: pointer;
}
.modal {
    @media screen and (max-width: 767px) {
        padding-right: 0px!important;
    }
}
.modal-body {
    padding: 3rem;
    @media screen and (max-width: 767px) {
        padding: 1rem;
    }
}
.adjusment-font-size-mobile-h4 {
    @media screen and (max-width: 767px) {
        font-size: 1.1rem;
    }
    @media screen and (max-width: 400px) {
        font-size: 1rem;
    }
}
// HOME BANNER
.home-banner {
    display: block;
    padding: 48px 0px;
    margin-top: 60px;
    @include full-background;
    .home-banner--inner {
        display: flex;
        align-items: center;
    }
    @media screen and (max-width: 991px) {
        padding-top: 50%;
        padding-bottom: 24px;
        background-position: bottom;
        transform: translateY(-200px);
        margin-bottom: 148px;
    }
}
.donation-card {
    width: 100%;
    max-width: 360px;
    background: $white;
    border-radius: $border-radius;
    padding: 36px;
    .donation-card--header,
    .donation-card--title,
    .donation-card--text,
    .donation-card--graph {
        display: block;
        margin-bottom: 18px;
    }
    .donation-card--title {
        font-size: 1.9rem;
        font-weight: 600;
        text-transform: capitalize;
    }
    .donation-card--text {
        font-size: 1rem;
    }
    .donation-card--graph {
        display: flex;
        background: $white;
        border: 1px solid $light-gray-1;
        border-radius: $border-radius;
        padding: 18px 0px;
        .graph-col {
            width: 50%;
            text-align: center;
            border-right: 1px solid $light-gray-1;
            .title {
                color: $gray-1;
            }
            .number {
                font-size: 2rem;
                font-weight: 600;
            }
            &:last-child {
                border-right: 0;
                margin-left: -1px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        max-width: 100%;
        transform: translateY(321px);
    }
}

// SECTION
.section {
    display: block;
    padding: 48px 0px;
    .section-what {
        font-weight: 600;
        font-size: 1.1rem;
        margin-bottom: 24px;
        display: block;
    }
    h4 {
        font-size: 1.3rem;
        margin-bottom: 24px;
    }
    &.section-lg {
        padding: 72px 0px;
    }
    @media screen and (max-width: 767px) {
        padding: 32px 0px;
        &.section-lg {
            padding: 48px 0px;
        }
    }
}

// CARD IMAGE
.card-image {
    @include full-background();
    padding: 32px;
    height: 400px;
    background-color: $light-gray;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    &:before {
        content: '';
        @include full-absolute(0px);
        background: rgba(0,0,0,0.2);
    }
    .card-image--inner {
        position: relative;
        z-index: 99;
        .card-image--text {
            color: $white;
        }
    }
}

// NUMBERS CIRCLE
.numbers-wrap {
    .col-md-3 {
        display: flex;
        justify-content: center;
        text-align: center;
    }
}
.numbers-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
    width: 200px;
    border-radius: 160px;
    padding: 18px;
    border: 1px solid $white;
    color: $white;
    background: rgba(255,255,255,0.1);
    .numbers-data {
        font-size: 2rem;
        font-weight: 600;
        margin-top: 10px;
    }
    @media screen and(max-width: 991px) {
        width: 160px;
        height: 160px;
        .numbers-data {
            font-size: 1.5rem;
        }
    }
    @media screen and(max-width: 767px) {
        margin-bottom: 24px;
        width: 100%;
        height: inherit;
    }
}
// TESTIMONIAL
.testimonial {
    display: flex;
    align-items: center;
    .testimonial-content {
        .name {
            font-size: .9rem;
            font-weight: 600;
            display: block;
            margin-top: 8px;
        }
    }
    .testimonial-photos {
        width: 80px;
        height: 80px;
        @include full-background();
        border-radius: 80px;
        margin-right: 18px;
        background-color: $light-gray;
        flex-shrink: 0;
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-items: center;
        text-align: center;
        .testimonial-photos {
            margin-bottom: 12px;
        }
    }
}
.numbers-wrap {
    .col-xs-6 {
        width: 50%!important;
    }
}
.card-overflow {
    overflow: hidden;
}
.card-video {
    height: 280px;
    @include full-background();
    background-color: $light-gray;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: $border-radius;
    overflow: hidden;
}
// GLIDE
.glide__bullets {
    bottom: 0;
    .glide__bullet {
        background-color: $gray;
        &.glide__bullet--active {
            background-color: $primary;
        }
        &:hover,
        &:focus {
            border: 0px;
        }
    }
}
// FOOTER
.footer-list {
    li {
        display: block;
        margin-bottom: 8px;
        a {
            color: $black;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
.section-footer {
    border-bottom: 12px solid $primary;
}
.bank-logo {
    width: 144px;
}
.section-bg-wow {
    @include full-background();
    background-image: url('../images/anak-anak-yatim-2.jpg');
    background-color: $light-gray;
    padding-top: 26%!important;
    @media screen and (max-width: 767px) {
        padding-top: 48px!important;
        background-image: url('../images/maxresdefault.jpg');
    }
}